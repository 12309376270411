@use "src/scss" as *;

.DebtButton {
  width: fit-content;
  position: relative;

  .button {
    height: 100%;
  }

  .modalWindow {
    width: 400px;
    position: absolute;
    top: 50px;
    right: 0;
    height: fit-content;
    background-color: #FFFFFF;
    border: 1px solid #EAECF0;
    box-shadow: 0 8px 8px -4px #10182808, 0 20px 24px -4px #10182814;
    border-radius: 16px;
    padding: 20px 24px 24px 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    z-index: 10;

    @media (max-width: 1000px) {
      width: 100%;
    }
  }
}