@use "/src/scss/" as *;

.MapControlPanel {
    display: flex;
    position: absolute;
    
    z-index: 2;
    &._instrumentsBlock {
        flex-direction: column;
        background-color: rgba($color: #ffffff, $alpha: 1);
        border-radius: 12rem;
        box-shadow: $box-shadow;
        top: 16rem;
        left: 16rem;
        padding: 16rem;
        gap: 6rem;
    }
    &._saveBlock {
        width: 328rem;
        max-width: 80%;
        gap: 8rem;
        bottom: 48rem;
        right: 16rem;
    }
}
