@use "../../scss/" as *;

.containerPage {
    padding: 19rem 12rem 0 12rem;
    height: 100%;
}

.tabsList {
    grid-auto-columns: auto;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0rem;
    thead {
        padding: 4rem 12rem;

        th {
            text-align: left;
            padding: 0 12rem 11rem 12rem;
            @include fnt(12, $color: $text-gray, $weight: 500, $height: 14);
            overflow: hidden;
            text-wrap: nowrap;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 160rem;
        }
    }
}

.tablRow {
    cursor: pointer;
    background-color: $gray-60;
    @include fnt(14, $color: $txt-color, $weight: 500, $height: 20);
    white-space: nowrap;

    .TableInput {
        display: block; 
        width: 90rem
    }
    .alignRight:first-child {
        .TableInput {
            padding-left: 8rem;
            width: 152rem
        }
    }
    .mini {
        max-width: 160rem;
        overflow: hidden;
        text-wrap: nowrap;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    td {
        padding: 4rem 0;
        a {
            display: block;
            padding: 0rem 12rem;
            overflow: hidden;
            @include fnt(14, 16, $color: $txt-color, $weight: 500);
        }

        &.cellOrangeTd {
            text-align: center;
            overflow: hidden;
            background-color: #ef6820;
            color: $white;
            width: 150rem;
            a {
                color: $white;
            }
        }
        &.cellGreenTd {
            text-align: center;
            overflow: hidden;
            background-color: #0dc268;
            color: $white;
            width: 150rem;
            a {
                color: $white;
            }
        }
        &.cellYelloTd {
            text-align: center;
            overflow: hidden;
            background-color: #eaaa08;
            color: $white;
            width: 150rem;
            a {
                color: $white;
            }
        }
        &.cellBlueTd {
            text-align: center;
            overflow: hidden;
            background-color: #22ccee;
            color: $white;
            width: 150rem;
            a {
                color: $white;
            }
        }
        &.cellGreyTd {
            text-align: center;
            overflow: hidden;
            background-color: #70707b;
            color: $white;
            a {
                color: $white;
            }
        }
    }

    &:nth-child(2n) {
        background-color: transparent;
        &:hover {
            background: rgba(0, 204, 204, 0.2);
        }
    }
    &._plan{
        background-color: #DCECFA;
    }
    &._fact{
        background-color: #CEF2E3;
    }
    &:hover {
        background: rgba(0, 204, 204, 0.2);
        border-radius: 4rem;
    }
    .alignRight {
        text-align: right;
    }
}

table td span {
    display: none;
}

// @media(max-width: 1200px) {
//     tbody {
//         position: relative;
//     }

//     table thead {
//         display: none;
//     }

//     table tr {
//         border-bottom: 0;
//         display: flex;
//         flex-direction: row;
//         flex-wrap: wrap;
//         margin-bottom: 20rem;
//     }

//     table td span {
//         display: block;
//         background: $gray-100;
//         color: $gray-900;
//         padding:0 5rem;
//         font-size: 10rem;
//         font-weight: bold;
//         position: absolute;
//         text-transform: uppercase;
//         top: 0;
//         left: 0;
//     }

//     .tablRow {
//         td {
//             border: 1rem solid;
//             margin: 0 -1rem -1rem 0;
//             padding-top: 25rem;
//             position: relative;
//             width: 50%;

//             &.cellOrangeTd{
//                 text-align: center;
//                 overflow: hidden;
//                 background-color: #EF6820;
//                 color: $white;
//                 padding: 5rem;
//                 width: 100%;

//                 span{
//                     display: none;
//                 }
//             }
//             &.cellGreenTd{
//                 text-align: center;
//                 overflow: hidden;
//                 background-color: #0DC268;
//                 color: $white;
//                 padding: 5rem;
//                 width: 100%;

//                 span{
//                     display: none;
//                 }
//             }
//             &.cellYelloTd{
//                 text-align: center;
//                 overflow: hidden;
//                 background-color: #EAAA08;
//                 color: $white;
//                 padding: 5rem;
//                 width: 100%;

//                 span{
//                     display: none;
//                 }
//             }
//             &.cellBlueTd{
//                 text-align: center;
//                 overflow: hidden;
//                 background-color: #22CCEE;
//                 color: $white;
//                 padding: 5rem;
//                 width: 100%;

//                 span{
//                     display: none;
//                 }
//             }
//             &.cellGreyTd{
//                 text-align: center;
//                 overflow: hidden;
//                 background-color: #70707B;
//                 color: $white;
//                 padding: 5rem;
//                 width: 100%;

//                 span{
//                     display: none;
//                 }
//             }
//         }
//     }
// }

// @media(max-width: 600px) {
//     .tablRow {
//         td {
//             border: 1rem solid;
//             margin: 0 -1rem -1rem 0;
//             padding-top: 25rem;
//             position: relative;
//             width: 100%;
//         }
//     }
// }

.reportCreatingText {
    display: flex;
    align-items: center;
    gap: 4rem;

    @include fnt(14, 16, $weight: 400, $font: $fontText, $color: $text-gray !important);
}

.loadingButton {
    pointer-events: none !important;
    background-color: $gray-300 !important;
}

.reportErrorText {
    display: flex;
    align-items: center;
    gap: 4rem;

    @include fnt(14, 16, $weight: 400, $font: $fontText, $color: $red !important);
}
.downloadReport {
    display: flex;
    align-items: center;
    gap: 4rem;
    padding: 6rem;

    @include fnt(14, 16, $weight: 400, $font: $fontText, $color: $blue-700 !important);
}

.TableInput {
    span {
        display: flex;
    }
    .formHeader {
        display: none;
    }
    .inputContainer {
        .flexInput .formInput {
            padding: 2rem 4rem;
            height: 16rem;
            // border: none;
            border-radius: 4rem;
        }
    }
}

.tablControlPanel {
    display: flex;
    gap: 4rem;
    width: 44rem;
    .TableIcon {
        display: block;
        border-radius: 4rem;
        padding: 2rem;
        img {
            display: block;
            height: 16rem;
            width: 16rem;
        }
        background-color: $black-blue;
        &._blue {
            background-color: $footer;
        }
        &._red {
            background-color: $error-500;
        }
    }
}